import { AthleteProfileModel } from "./AthleteProfileModel";
import { SelfAssessmentModel } from "@/models/athlete/SelfAssessmentModel";
import { AthleteAssessmentDataModel } from "@/models/athlete/AthleteAssessmentDataModel";
import { ScoutingReportEvaluationAverage } from '@best-athletes/ba-types';
import { ScoutingReportModel } from "../scoutingReport/ScoutingReportModel";
import { MindsetReportModel } from '@/models/mindset/MindsetReportModel';
import { TeamModel } from "@/models/team";
import { isEmptyArray, isNotEmpty } from "@/pipes";
import { formatPositionShort } from "@/helpers/strings"

export class RecruitingProfileModel extends AthleteProfileModel{
	selfAssessment: SelfAssessmentModel | null = null;
	physicalPerformance: AthleteAssessmentDataModel | null = null;
	scoutingAverage: ScoutingReportEvaluationAverage | null = null;
	scoutingReport: ScoutingReportModel | null = null;
	mindsetReport: MindsetReportModel | null = null;
	teams: TeamModel[] = [];
	athleteProfile: AthleteProfileModel;
	rating: number;

	load(data: Record<string, any>): this{
		const { selfAssessment, physicalPerformance, scoutingAverage, scoutingReport, mindsetReport, teams, ...athleteProfile } = data;
		// Convert the data to models; needed as there are getters and setters that are required
		this.physicalPerformance = physicalPerformance ? new AthleteAssessmentDataModel().load(physicalPerformance) : null;
		this.selfAssessment = selfAssessment ? new SelfAssessmentModel().load(selfAssessment) : null;
		this.scoutingAverage = scoutingAverage;
		this.scoutingReport = scoutingReport ? new ScoutingReportModel().load(scoutingReport) : null;
		this.mindsetReport = mindsetReport ? new MindsetReportModel().load(mindsetReport) : null;		
		this.athleteProfile = athleteProfile ? new AthleteProfileModel(athleteProfile) : null;
		if( teams ) this.teams = teams.map((team: any) => new TeamModel().load(team));

		return this;
	}

	get RecruitingFullName(): string {
		if( this.athleteProfile ) return this.athleteProfile.FullName;
		return this.FullName;
	}
	get RecruitingAgeGroup(): string {
		if( this.athleteProfile ) return this.athleteProfile.AgeGroup;
		return this.AgeGroup;
	}
	private RecruitingTeam(teamId: string = undefined): TeamModel {
		if( isEmptyArray(this.teams)) return undefined;

		let team: TeamModel;
		if( teamId ) team = this.teams.find(t => t.id === teamId);
		if( !team ) team = this.teams[this.teams.length - 1];
		return team;
	}
	RecruitingTeamName(teamId: string = undefined): string {
		const team: TeamModel = this.RecruitingTeam(teamId);
		return team? team.name : '';
	}
	RecruitingNumber(teamId: string = undefined): string {
		const team: TeamModel = this.RecruitingTeam(teamId);
		return team? team.players.find(p => p.athleteId === ( this.athleteId? this.athleteId : this.athleteProfile.id )).number : '';
	}
	RecruitingPosition(teamId: string = undefined): string {
		const team: TeamModel = this.RecruitingTeam(teamId);
		if( team ) {
			const player = team.players.find(p => p.athleteId === ( this.athleteId? this.athleteId : this.athleteProfile.id ));
			if( player && isNotEmpty(player.position) ) return formatPositionShort(player.position);
		}
		return this.athleteProfile.PositionShort;
	}
	get RecruitingDominantSide(): string {
		if( this.athleteProfile ) return this.athleteProfile.dominantSide;
		return this.dominantSide;
	}

	get RecruitingAcceleration(): string {
		if( !this.physicalPerformance ) return '';
		return this.physicalPerformance.Acceleration(true, false);
	}
	get RecruitingSpeed(): string {
		if( !this.physicalPerformance ) return '';
		return this.physicalPerformance.Speed(true, false);
	}
	get RecruitingAgility(): string {
		if( !this.physicalPerformance ) return '';
		return this.physicalPerformance.Agility(false, false);
	}
	get RecruitingPower(): string {
		if( !this.physicalPerformance ) return '';
		return this.physicalPerformance.Power(true, false);
	}
	get RecruitingRecovery(): string {
		if( !this.physicalPerformance ) return '';
		return this.physicalPerformance.Recovery(true, false);
	}


	RecruitingScoutingValue(item: string, fourPlusThreshold: number = 4.75): string {
		if( !!this.scoutingAverage ) {
			return this.scoutingAverage[item] > fourPlusThreshold? "4+" : this.scoutingAverage[item].toFixed(1);
		}
		if( !!this.scoutingReport ) {
			return this.scoutingReport.EvaluationStr(item, fourPlusThreshold);
		}
		return '';
	}
	get RecruitingTechnical(): string {
		return this.RecruitingScoutingValue('technical');
	}
	get RecruitingTactical(): string {
		return this.RecruitingScoutingValue('tactical');
	}
	get RecruitingPhysical(): string {
		return this.RecruitingScoutingValue('physical');
	}
	get RecruitingMental(): string {
		return this.RecruitingScoutingValue('mental');
	}


	RecruitingMindsetValue(item: string, noviceThreshold: number = 20): string {
		if( !this.mindsetReport ) return '';
		return this.mindsetReport.EvaluationStr(item, noviceThreshold);
	}
	get RecruitingMentalToughness(): string {
		return this.RecruitingMindsetValue('mentalToughness');
	}
	get RecruitingMentalExecution(): string {
		return this.RecruitingMindsetValue('mentalExecution');
	}
	get RecruitingCoachability(): string {
		return this.RecruitingMindsetValue('coachability');
	}

}