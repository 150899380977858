import { FrontEndModel } from '@/models/FrontEndModel';
import { isNotEmpty } from '@/pipes';
import { MissingDataBehaviour, BARatingColor } from "@best-athletes/ba-types";

// Each factor has a weight of ax^2 + bx + c
export class BARatingFormulaFactor {
	title: string;
	description?: string;
	key: string;
	min: number;
	max: number;
	a: number = 0;
	b: number = 1;
	c: number = 0;
	missingBehaviour: MissingDataBehaviour = MissingDataBehaviour.ignore;
	default: number = 0;

	load<T = this>(obj: Partial<T>): this{
		Object.assign(this, obj);
		this.default = Number((this.max * 0.33).toFixed(1));
		return this;
	}
}
export class BARatingFormulaLevels {
	title: string;
	floor: number;
	color: string;
}
export class BARatingFormula extends FrontEndModel {
	version: number = 1;
	title: string = "New Formula";
	created: Date = new Date();
	active: boolean = false;
	archive: boolean = false;
	factors: Array<BARatingFormulaFactor>;
	levels: Array<BARatingFormulaLevels> = [
		{ title: "85th Percentile", floor: 85, color: BARatingColor.International },
		{ title: "70th Percentile", floor: 70, color: BARatingColor.Professional },
		{ title: "55th Percentile", floor: 55, color: BARatingColor.HighPerformance },
		{ title: "40th Percentile", floor: 40, color: BARatingColor.Competitive },
		{ title: "25th Percentile", floor: 25, color: BARatingColor.Recreational },
		{ title: "Developing", floor: 0, color: BARatingColor.Developing },
	];

	levelsSorted: Array<BARatingFormulaLevels>;

	constructor() {
		super();

		this.factors = [
			new BARatingFormulaFactor().load({ title: "Mental Toughness", key: 'mindsetReport.mentalToughness', min:1, max:100, a:0, b:1, c:0, missingBehaviour:MissingDataBehaviour.default, default:30}),
			new BARatingFormulaFactor().load({ title: "Mental Execution", key: 'mindsetReport.mentalExecution', min:1, max:100, a:0, b:1, c:0, missingBehaviour:MissingDataBehaviour.default, default:30}),
			new BARatingFormulaFactor().load({ title: "Coachability", key: 'mindsetReport.coachability', min:1, max:100, a:0, b:1, c:0, missingBehaviour:MissingDataBehaviour.default, default:30}),
			new BARatingFormulaFactor().load({ title: "Intangibles", key: 'intangibles', min:1, max:100, a:0, b:1, c:0, missingBehaviour:MissingDataBehaviour.ignore, default:50}),
		];			
	}
	load(obj: Partial<BARatingFormula>): this {
		Object.assign(this, obj);

		if( isNotEmpty(obj.created) ) this.created = new Date(obj.created);
		if( isNotEmpty(obj.levels) ) this.levelsSorted = this.levels.sort((a,b) => b.floor - a.floor);
		return this;
	}

}

export const AllRatingFormulaFactors: Array<Partial<BARatingFormulaFactor>> = [
	// Biographical
	{ title: "Age", description: "Age in years", key:'physicalPerformance.age', min:12, max:20},
	{ title: "Height", description: "Height in cm", key:'physicalPerformance.standingHeight', min:152, max:200},
	{ title: "Weight", description: "Weight in kg", key:'physicalPerformance.mass', min:40, max:90},
	// Education
	{ title: "GPA", description: "Grade point average", key:'highschoolGPA', min:0, max:4.0},
	{ title: "SAT Score", description: "Scholastic Assessment Test", key:'SATScore', min:400, max:1600},
	{ title: "ACT Score", description: "American College Test", key:'ACTScore', min:1, max:36},
	// Physical
	{ title: "Acceleration", description: "10m sprint (m/s)", key: 'physicalPerformance.acceleration', min:3.0, max:6.5},
	{ title: "Speed", description: "20-35m sprint (m/s)", key: 'physicalPerformance.speed', min:5.0, max:11.0},
	{ title: "Agility", description: "Drop Jump", key:'physicalPerformance.reactiveStrengthIndex', min:0.5, max:2.0},
	{ title: "Power", description: "Counter Movement Jump", key:'physicalPerformance.power', min:1000, max:5000},
	{ title: "Recovery", description: "YoYo Recovery Test", key:'physicalPerformance.maximalAerobicVelocity', min:13.5, max:20.0},
	// Mindset
	{ title: "Mental Toughness", description: "Ability to perform well under adversity", key: 'mindsetReport.mentalToughness', min:1, max:100},
	{ title: "Composure", description: "Emotional stability", key: 'mindsetReport.composure', min:1, max:100},
	{ title: "Confidence", description: "Assertiveness. Belief in one's abilities", key: 'mindsetReport.confidence', min:1, max:100},
	{ title: "Motivational Drive", description: "Aspiration and competitive desire", key: 'mindsetReport.drive', min:1, max:100},
	{ title: "Grit", description: "Resiliency. Resolve to persevere", key: 'mindsetReport.grit', min:1, max:100},
	{ title: "Mental Execution", description: "Ability to execute accurately and timely", key: 'mindsetReport.mentalExecution', min:1, max:100},
	{ title: "Adaptability", description: "Openness to try new things", key: 'mindsetReport.adaptability', min:1, max:100},
	{ title: "Decisiveness", description: "Ability to make quick decisions", key: 'mindsetReport.decisiveness', min:1, max:100},
	{ title: "Focus Speed", description: "Reaction time and accuracy", key: 'mindsetReport.focusSpeed', min:1, max:100},
	{ title: "Coachability", description: "Willingness to apply coaching", key: 'mindsetReport.coachability', min:1, max:100},
	{ title: "Attention to Detail", description: "Ability to listen and digest", key: 'mindsetReport.attentionToDetail', min:1, max:100},
	{ title: "Conventionality", description: "Propensity to follow", key: 'mindsetReport.conventionality', min:1, max:100},
	{ title: "Growth Mindset", description: "Openess to lean and apply", key: 'mindsetReport.growthMindset', min:1, max:100},
	{ title: "Information Processing", description: "Applying new knowledge", key: 'mindsetReport.informationProcessing', min:1, max:100},
	// Scouting
	{ title: "Technical", description: "4 Corners: Technical", key: 'evaluationMatrix.technical', min:1, max:5},
	{ title: "Tactical", description: "4 Corners: Tactical", key: 'evaluationMatrix.tactical', min:1, max:5},
	{ title: "Physical", description: "4 Corners: Physical", key: 'evaluationMatrix.physical', min:1, max:5},
	{ title: "Mental", description: "4 Corners: Mental", key: 'evaluationMatrix.mental', min:1, max:5},
	{ title: "Attacking Organization", description: "6 Moments: Attacking Organization", key: 'evaluationMatrix.attackingOrganization', min:1, max:5},
	{ title: "Attacking Transition", description: "6 Moments: Attacking Transition", key: 'evaluationMatrix.attackingTransition', min:1, max:5},
	{ title: "Attacking Set Plays", description: "6 Moments: Attacking Set Plays", key: 'evaluationMatrix.attackingSetPlays', min:1, max:5},
	{ title: "Defending Organization", description: "6 Moments: Defending Organization", key: 'evaluationMatrix.defendingOrganization', min:1, max:5},
	{ title: "Defending Transition", description: "6 Moments: Defending Transition", key: 'evaluationMatrix.defendingTransition', min:1, max:5},
	{ title: "Defending Set Plays", description: "6 Moments: Defending Set Plays", key: 'evaluationMatrix.defendingSetPlays', min:1, max:5},
	{ title: "Competitive", description: "CRISP: Competitive", key: 'evaluationMatrix.competitive', min:1, max:5},
	{ title: "Resilience", description: "CRISP: Resilience", key: 'evaluationMatrix.resilience', min:1, max:5},
	{ title: "Intelligence", description: "CRISP: Intelligence", key: 'evaluationMatrix.intelligence', min:1, max:5},
	{ title: "Speed", description: "CRISP: Speed", key: 'evaluationMatrix.speed', min:1, max:5},
	{ title: "Presence", description: "CRISP: Presence", key: 'evaluationMatrix.presence', min:1, max:5},
	{ title: "Scouting Performance", description: "Overall Score", key: 'evaluationMatrix.performanceScore', min:1, max:5},
	{ title: "Scouting Potential", description: "Overall Potential", key: 'evaluationMatrix.potentialScore', min:1, max:5},
	// Other
	{ title: "Intangibles", description: "Unseen qualities that determine true impact", key:'intangibles', min:1, max:100},
	{ title: "Highlight Video", description: "Does the athlete have a highlight video", key:'highlightVideo', min:0, max:1},
	{ title: "Profile Photo", description: "Does the athlete have a profile photo", key:'pictureUrl' },
	{ title: "Email", description: "Does the athlete have an email address", key:'email' },
	{ title: "Phone Number", description: "Does the athlete have a phone number", key:'phoneNumber'},
	{ title: "Biography", description: "Does the athlete have a biography", key:'shortBio'},
]