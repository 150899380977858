// baratings.ts

export enum MissingDataBehaviour {
	ignore = "ignore",
	default = "default",
	zero = "zero",
}

export enum BARatingColor {
	International = "baColorInternational",
	Professional = "baColorProfessional",
	HighPerformance = "baColorHighPerformance",
	Competitive = "baColorCompetitive",
	Recreational = "baColorRecreational",
	Developing = "baColorUnranked",
	Primary = "primary",
	Secondary = "secondary",
	Accent = "accent",
	Success = "success",
	Info = "info",
	Warning = "warning",
	Error = "error",
}

